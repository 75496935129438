import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import * as useragent from 'express-useragent';

export const USER_AGENT = new InjectionToken<useragent.Details | undefined>('UserAgent');

@Injectable({
    providedIn: 'root',
})
export class UserAgentService {
    constructor(@Optional() @Inject(USER_AGENT) private agent: any) {}

    get userAgent() {
        return this.agent;
    }
}
