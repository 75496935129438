import { Directive, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SeoService } from '@core/seo/seo.service';
import { HasMetadata } from '@core/seo/site-meta.types';
import { isObservable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'router-outlet',
})
export class PageMetadataDirective implements OnDestroy {
    private readonly subscription: Subscription;

    constructor(routerOutlet: RouterOutlet, seoService: SeoService) {
        this.subscription = routerOutlet.activateEvents
            .pipe(
                switchMap((component: Partial<HasMetadata>) => {
                    if (isObservable(component.pageMetadata)) {
                        return component.pageMetadata;
                    } else {
                        return of(undefined);
                    }
                }),
            )
            .subscribe(metadata => {
                seoService.setPageMetadata(metadata);
            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
