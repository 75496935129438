import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserAgentService } from '@core/providers/user-agent.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResponsiveService {
    private isMobile$: Observable<boolean>;
    private isTablet$: Observable<boolean>;
    private isDesktop$: Observable<boolean>;

    constructor(
        private breakpoint: BreakpointObserver,
        private userAgent: UserAgentService,
        @Inject(DOCUMENT) private _doc: any,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.isMobile$ = this.breakpoint.observe('(max-width: 575px)').pipe(
            map(value => value.matches),
            startWith(userAgent.userAgent?.isMobile || false),
        );
        this.isTablet$ = this.breakpoint.observe('(max-width: 767px)').pipe(
            map(value => value.matches),
            startWith(userAgent.userAgent?.isTablet || false),
        );
        // We count tablet as desktop as well
        this.isDesktop$ = this.breakpoint.observe('(min-width: 575px)').pipe(
            map(value => value.matches),
            startWith(userAgent.userAgent?.isDesktop || false),
        );
    }

    public isMobile(): Observable<boolean> {
        return this.isMobile$;
    }

    public isTablet(): Observable<boolean> {
        return this.isTablet$;
    }
    public isDesktop(): Observable<boolean> {
        return this.isDesktop$;
    }
}
