import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponsiveService } from '@core/providers/responsive.service';
import { LayoutService } from '@core/providers/state/layout.service';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { Observable } from 'rxjs';

@Component({
    selector: 'fainin-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    animations: [
        trigger('slideUp', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(-100%)',
                }),
                animate(300),
            ]),
            transition(':leave', [
                animate(
                    300,
                    style({
                        opacity: 0,
                        transform: 'translateY(-100%)',
                    }),
                ),
            ]),
        ]),
        trigger('slideDown', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(100%)',
                }),
                animate(300),
            ]),
            transition(':leave', [
                animate(
                    300,
                    style({
                        opacity: 0,
                        transform: 'translateY(100%)',
                    }),
                ),
            ]),
        ]),
    ],
})
export class LayoutComponent implements OnInit, OnDestroy {
    isMobile$: Observable<boolean>;

    /* Layout States */
    showSubheader$: Observable<boolean>;
    showTabBar$: Observable<boolean>;

    @HostBinding('class.block-scrolling')
    blockScrolling: boolean;

    constructor(
        private responsive: ResponsiveService,
        private stateService: StateService,
        protected router: Router,
        private layoutService: LayoutService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.isMobile$ = this.responsive.isMobile();
        this.showTabBar$ = this.layoutService.select(layout => layout.showTabBar);

        this.layoutService
            .select(layout => layout.blockScrolling)
            .pipe(untilDestroyed(this))
            .subscribe((blockScrolling): void => {
                this.blockScrolling = blockScrolling;
            });
    }

    ngOnInit(): void {
        this.showSubheader$ = this.stateService.select(state => state.showSubHeader);
        // Add event listener to detect outside clicks
        // Add the event listener delayed, because otherwise it will be triggered directly
        setTimeout(() => {
            document.addEventListener('click', this.outsideHeaderClickListener);
        }, 300);
        // this.listenForChatListRoutes();
    }

    showFooter() {
        //TODO: This needs to be improved and moved to routing
        return (
            !this.router.url.includes('chat') &&
            !this.router.url.includes('order') &&
            !this.router.url.includes('requests') &&
            !this.router.url.includes('account') &&
            !this.router.url.includes('product') &&
            !this.router.url.includes('auth')
        );
    }

    showSubHeader() {
        return this.router.url.includes('/account');
    }

    showHeader() {
        return !this.router.url.includes('linktree');
    }

    hideTabBar() {
        //TODO: This needs to be improved and moved to routing
        return (
            this.router.url.includes('account/requests/') ||
            this.router.url.includes('account/orders/') ||
            this.router.url.includes('account/edit-profile') ||
            this.router.url.includes('account/product-edit') ||
            this.router.url.includes('account/chats') ||
            this.router.url.includes('/product/') ||
            this.router.url.includes('/linktree') ||
            this.router.url.includes('/auth/sign-up-wizard')
        );
    }

    /**
     * Listener to detect clicks outside the drawer component
     * @param event
     */
    outsideHeaderClickListener = (event: MouseEvent) => {
        if (this.stateService.currentState('showSubHeader')) {
            const header = this.document.getElementById('header');
            const subHeader = this.document.getElementById('sub-header');
            if (
                event.target &&
                header &&
                !header.contains(event.target as HTMLElement) &&
                subHeader &&
                !subHeader.contains(event.target as HTMLElement)
            ) {
                this.stateService.setState('showSubHeader', false);
            }
        }
    };

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }
}
