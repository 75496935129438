export default {
    title: 'fainin | оренда дешево та оренда застрахована | заробляйте гроші',
    keywords:
        'Віддавати речі в оренду, орендувати речі, позичати онлайн, позичати вживані речі, оренда обладнання онлайн, оренда страхування, оренда студії, топ-10 оренди, прокат мистецтва Гамбург',
    description:
        'fainin — це найбезпечніша спільнота для оренди та позики. З Дизайн на довіру ми пропонуємо вам можливість отримати вигоду від кредитування, оренди та спільного використання.',
    faq: {
        title: 'Часті запитання | Безпечна оренда',
        description:
            'З fainin ми забезпечуємо безпечну оренду та позику. Хочете знати, як ми це робимо? Увійдіть, щоб отримати персональну підтримку.',
        keywords:
            'Здавати речі в оренду, орендувати речі, позичати онлайн, позичати вживані речі, що я можу орендувати, який захист орендаря, що я можу позичити, де я можу щось позичити, як застрахувати оренду, заробити гроші під час оренди',
    },
    about: {
        title: 'Про нас | Ваша безпечна спільнота для оренди та позики.',
        description:
            'fainin пропонує безпечну оренду та позику у вашій спільноті. Таким чином ми встановлюємо стійке споживання і пропонуємо кожному можливість зробити світ трішки кращим. ',
        keywords:
            'Здавати речі в оренду, орендувати речі, позичати онлайн,навіщо оренда, принципи, зробити світ кращим, безпечна здача в оренду, свобода через обмін, здача речей, яким можна довіряти',
    },
    blog: {
        title: 'Блог fainin: як безпечно ділитися',
        description:
            'Отримуйте натхнення в блозі fainin з новинами, статтями та ресурсами від тих, хто налаштований на екологічність',
        keywords:
            'Здавати речі в оренду, орендувати речі, позичати в Інтернеті, позичати вживані речі, що я можу орендувати, який захист орендаря, що я можу позичити, де я можу щось позичити, як застрахувати оренду, заробити гроші під час оренди',
    },
    search: {
        title: 'fainin | Продукти у вашому районі',
        description:
            'У fainin ви знайдете всі речі для безпечного обміну в місті. Використовуйте все необхідне та отримайте переваги від безпечної оренди та позики.',
        keywords:
            'Здавати речі в оренду, оренда речей, оренда онлайн, оренда вживаного обладнання, оренда обладнання онлайн, оренда студій онлайн, оренда застрахованих, безпечна оренда',
    },
    linktree: {
        title: 'fainin | Linktree',
        description:
            'Приєднуйтесь до спільноти fainins sharing. Разом ми можемо зробити оренду, кредитування та стале споживання простішими, ніж будь-коли.',
        keywords:
            'спільнота спільного користування, місячна оренда, спортивний інвентар, оренда сейфів, оренда аудіо книг, оренда приставок, оренда торгових марок',
    },
    contact: {
        title: 'Контакти для fainin',
        description:
            'Як ми можемо вам допомогти? Зв’яжіться з нами через contact@fainin.com або просто зателефонуйте нам за номером +491753477656.',
    },
    privacy: {
        title: 'Конфіденційність даних fainin',
        description:
            'Ми можемо зберігати вашу особисту інформацію, якщо це необхідно для запобігання шахрайству, пошкодження чи втрати.',
    },
    imprint: {
        title: 'Відбиток - fainin',
        description:
            'Вихідні дані. Постачальник веб-сайту: компанія з обмеженою відповідальністю fainin GmbH - Mühlenkamp 4, 22303 Hamburg.',
    },
    terms: {
        title: 'Правила та умови fainin',
        description:
            'Як кредитор fainin пропонує вам ідеальну платформу оренди, щоб поділитися своїми речами, досвідом або іншими послугами позики з нашою жвавою спільнотою.',
    },
};
