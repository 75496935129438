export default {
    topCategories: 'Top Categories',
    register: 'Register',
    login: 'Login',
    forgotPassword: 'Forgot Password',
    resetPassword: 'Reset Password',
    email: 'Email',
    downloadIOSApp: 'Download iOS App',
    downloadAndroidApp: 'Download Android App',
    ctaSignup: 'CTA Signup now',
    cookieSettingsButton: 'Cookie settings button',
    signupButton: 'Signup button',
    desktopLoginButton: 'Desktop login button',
    viewAll: 'View all',
    breadcrumb: 'Navigation for sub menue',
    mobileMenuToggle: 'Menu toggle',
    location: 'Location',
    cookieConsent: {
        customizeCookieConsent: 'Customize Cookie Consent',
        acceptCookieConsent: 'Accept Cookie Consent',
        backClicked: 'Back clicked',
    },
    closeActionSheet: 'close action sheet',
    remove: 'Remove',
    add: 'Add',
    close: 'Close',
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    dashboard: {
        downloadAndroidApp: 'Download Android App',
        downloadIOSApp: 'Download iOS App',
    },
    searchSheet: {
        loadMore: 'Load More',
    },
    newsLetterSignup: {
        email: 'email',
    },
    myProfile: {
        showMoreReviews: 'Show More Reviews',
    },
    productUpload: {
        currentLocation: 'Current Location',
        save: 'Save',
        deleteLocation: 'Delete Location',
        addLocation: 'Add Location',
        uploadLocation: 'Upload Location',
        getCurrentLocationButton: 'Get Current Location Button',
    },
    voucher: {
        code: 'Voucher code',
        submit: 'Activate voucher',
    },
    requirements: 'Requirements',
    showMore: 'Show more',
};
