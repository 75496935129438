import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveProfile, ActiveProfileFragment, Profile, TalkJsSignature } from '@api/generated-types';
import { TALKJS_SIGNATURE } from '@api/vendure/documents.graphql';
import { DataService } from '@core/providers/data/data.service';
import { NotificationService } from '@core/providers/notification/notification.service';
import { LayoutService } from '@core/providers/state/layout.service';
import { StateService } from '@core/providers/state/state.service';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import Talk from 'talkjs';

const log = new Logger('FaininChatService');

@Injectable({
    providedIn: 'root',
})
export class TalkjsService {
    private chatInitializedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private chatSelectedSubject$: BehaviorSubject<Talk.ConversationBuilder | undefined> = new BehaviorSubject<
        Talk.ConversationBuilder | undefined
    >(undefined);
    private currentTalkUser: Talk.User | undefined;
    private session: Talk.Session | undefined;
    private inbox: Talk.Inbox | undefined;
    private STANDARD_USER_ROLE: string = 'default';
    private ADMIN_USER_ROLE: string = 'admin';

    constructor(
        private dataService: DataService,
        private router: Router,
        private stateService: StateService,
        private layoutService: LayoutService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
    ) {
        this.checkForAuthenticationStatus();
    }

    public get chatSessionRef(): Talk.Session | undefined {
        return this.session;
    }

    public get onChatSessionInitialized(): Observable<boolean> {
        return this.chatInitializedSubject$ as Observable<boolean>;
    }

    public get onConversationVisited(): Observable<Talk.ConversationBuilder | undefined> {
        return this.chatSelectedSubject$ as Observable<Talk.ConversationBuilder | undefined>;
    }

    /**
     * Parameters are interchangeable for self and other users because the
     * way they are hashed returns the same id regardless of order
     * @param id1
     * @param id2
     */
    public getOneOnOneConversationId(id1: string, id2: string): string {
        return Talk.oneOnOneId(id1, id2);
    }

    public async mountInbox(chatContainer: HTMLElement): Promise<void> {
        await Talk.ready;
        this.inbox = await this.session?.createInbox();
        await this.inbox?.mount(chatContainer);

        this.inbox?.onConversationSelected(event => {
            this.layoutService.setState('showTabBar', event.conversation === null);
        });

        this.inbox?.onCustomConversationAction(event => {
            /*
            {
                "action": "goToProfile",
                "params": {
                    "userId": "3ad5f6c1-40f2-4b62-bb6e-eb975f411d1a"
                },
            }
             */
            if (event.action === 'goToProfile') {
                const { userId } = event.params;
                if (userId) {
                    this.router.navigate([`/profile/${userId}`]);
                }
            }
        });
    }

    public async initializeUserAndSession(): Promise<void> {
        log.debug('Initializing TalkJs chat session');
        await Talk.ready;
        const activeProfile: ActiveProfileFragment | undefined = this.stateService.currentState('activeProfile');
        if (activeProfile) {
            this.currentTalkUser = await this.getMappedUser(activeProfile);
            const signature: string = await this.getIdentityVerificationSignature();
            this.createChatSession(signature);
        } else {
            log.error(
                'Cant fetch active profile even after signing in, please refresh the application and try again to initialize chat',
            );
            // TODO localize
            this.notificationService.error(
                'There was a problem initializing profile, please refresh the application and try again to initialize chat',
            );
            return;
        }
    }

    /**
     * Destroy session under two conditions:
     * 1. User signs out (manually done)
     * 2. User closes tab (automatic)
     */
    public destroyChatSession(): void {
        this.session?.destroy();
        this.invalidateSessionData();
        this.stateService.setState('totalUnreadMessages', 0);
        log.debug('Destroyed TalkJs chat session');
    }

    public async deselectChat(): Promise<void> {
        this.chatSelectedSubject$.next(undefined);
    }

    public async goToConversationWithOtherUser(otherUser: Profile): Promise<Talk.ConversationBuilder | undefined> {
        if (this.currentTalkUser) {
            const conversation: Talk.ConversationBuilder | undefined = await this.initializeConversation(otherUser);
            this.router.navigate(['/account/chats']).then(() => {
                this.chatSelectedSubject$.next(conversation);
            });
        } else {
            this.notificationService.info(this.translateService.instant('content.productDetail.loginToChat'));
            return undefined;
        }
    }

    public async openChat(conversation: Talk.ConversationBuilder): Promise<void> {
        await Talk.ready;
        log.debug('Opening conversation:');
        log.debug(conversation);
        await this.inbox?.select(conversation);
    }

    private createChatSession(signature: string): void {
        if (this.currentTalkUser) {
            log.debug('Creating a new chat session!');
            this.session = new Talk.Session({
                appId: environment.talkJsAppId,
                me: this.currentTalkUser,
                signature: signature,
            });
            this.chatInitializedSubject$.next(true);
        } else {
            log.error('Please initialize currentUser to create a chat session!');
        }
    }

    private async getIdentityVerificationSignature(): Promise<string> {
        return await lastValueFrom(
            this.dataService.query<TalkJsSignature.Query>(TALKJS_SIGNATURE, {}, 'network-only').pipe(
                map(res => res.talkSignature),
                catchError(error => {
                    return throwError(error);
                }),
                take(1),
            ),
        );
    }

    private async initializeConversation(profile: Profile): Promise<Talk.ConversationBuilder | undefined> {
        if (this.currentTalkUser) {
            const otherUser: Talk.User = await this.getMappedUser(profile);
            const conversationId: string = this.getOneOnOneConversationId(this.currentTalkUser.id, otherUser.id);
            const conversation: Talk.ConversationBuilder | undefined = await this.session?.getOrCreateConversation(
                conversationId,
            );
            conversation?.setParticipant(this.currentTalkUser);
            conversation?.setParticipant(otherUser);
            return conversation;
        }
    }

    private checkForAuthenticationStatus() {
        if (this.stateService.currentState('activeProfile')) {
            this.initializeUserAndSession();
        }
    }

    private invalidateSessionData() {
        this.session = undefined;
        this.inbox = undefined;
        this.chatInitializedSubject$.next(false);
    }

    private async getMappedUser(currentUser: ActiveProfile.Fragment | Profile): Promise<Talk.User> {
        await Talk.ready;
        return new Talk.User({
            id: currentUser.id,
            name: currentUser.name,
            photoUrl: currentUser.featuredAsset?.source,
            role: this.STANDARD_USER_ROLE,
        });
    }
}
