<div id="header-wrapper" class="header-wrapper">
    <div #header id="header" *ngIf="showHeader()">
        <ng-content select="fainin-layout-header"></ng-content>
    </div>
</div>
<div
    #navigationHeader
    *ngIf="(isMobile$ | async) === false && showSubHeader()"
    id="navigation-header"
    class="sub-header">
    <ng-content select="fainin-layout-navigation-header"></ng-content>
</div>
<div class="content">
    <ng-content></ng-content>
</div>
<fainin-tab-bar class="tabbar" *ngIf="showTabBar$ | async" [@slideDown]></fainin-tab-bar>
<div class="footer" *ngIf="showFooter()">
    <div class="container" [class.tab-bar-offset]="isTabBar$ | async">
        <ng-content select="fainin-layout-footer"></ng-content>
    </div>
</div>
