export default {
    title: 'fainin | аренда дешево и аренда застрахована | заработать деньги',
    keywords:
        'Аренда вещей, аренда вещей, аренда онлайн, аренда б/у, аренда оборудования, аренда онлайн, аренда страховки, аренда студии, топ 10 аренда, аренда искусства Гамбург',
    description:
        'fainin - это самое безопасное сообщество совместного использования для кредитования и аренды. С Design-For-Trust мы предлагаем вам возможность получать прибыль от кредитования, аренды и совместного использования.',
    faq: {
        title: 'Часто задаваемые вопросы | Безопасная аренда',
        description:
            'С fainin мы создаем безопасную аренду и совместное использование. Хотите узнать, как мы это делаем? Войдите в систему, чтобы получить индивидуальную поддержку.',
        keywords:
            'Аренда вещей, аренда вещей, аренда онлайн, аренда б/у, что я могу арендовать, какая защита арендатора, что я могу арендовать, где я арендую, как застраховать аренду, зарабатывание денег на аренде',
    },
    about: {
        title: 'О нас | Ваше безопасное сообщество по аренде жилья',
        description:
            'fainin предлагает безопасную аренду и кредитование в вашем сообществе совместного пользования. Тем самым мы создаем устойчивое потребление и предлагаем каждому возможность сделать мир немного лучше.',
        keywords:
            'аренда вещей, аренда вещей, аренда онлайн, аренда б/у, зачем арендовать, принципы, улучшить мир, безопасная аренда, свобода через обмен, надежная аренда',
    },
    blog: {
        title: 'Блог fainin: Как безопасно делиться',
        description:
            'Вдохновляйтесь новостями, статьями и ресурсами от людей и друзей, придерживающихся принципов устойчивого развития, в блоге fainin.',
        keywords:
            'Аренда вещей, Аренда вещей, Кредитование онлайн, Кредитование second hand, Что я могу арендовать, Что защищает арендатора, Что я могу дать взаймы, Где я даю взаймы, Как застраховать аренду, Заработать на аренде',
    },
    search: {
        title: 'fainin | Продукты в вашем районе',
        description:
            'В fainin вы можете найти все необходимое для безопасного совместного проживания в городе. Пользуйтесь всем необходимым и получайте выгоду от безопасной аренды и лизинга.',
        keywords:
            'Аренда вещей, аренда вещей, аренда онлайн, аренда б/у, аренда оборудования, аренда онлайн, аренда студий, застрахованная аренда, безопасная аренда',
    },
    linktree: {
        title: 'fainin | Linktree',
        description:
            'Станьте частью сообщества совместного пользования fainins. Вместе мы сможем сделать аренду, кредитование и устойчивое потребление проще, чем когда-либо.',
        keywords:
            'сообщество совместного пользования, аренда на месяц, спортивное оборудование, аренда сейфов, одолжить звуковые книги, аренда консолей, аренда брендов',
    },
    contact: {
        title: 'Контакты для fainin',
        description:
            'Как мы можем вам помочь? Свяжитесь с нами по адресу contact@fainin.com или просто позвоните нам по телефону +491753477656.',
    },
    privacy: {
        title: 'Датаприватность fainin',
        description:
            'Мы можем сохранять вашу личную информацию по мере необходимости для предотвращения мошенничества, ущерба или потери.',
    },
    imprint: {
        title: 'Импринт - fainin',
        description:
            'Оттиск. Поставщик веб-сайта: fainin GmbH, общество с ограниченной ответственностью - Мюленкамп 4, 22303 Гамбург.',
    },
    terms: {
        title: 'Положения и условия fainin',
        description:
            'fainin предлагает вам платформу для аренды, чтобы поделиться своими предметами, опытом или другими услугами кредитования с нашим активным сообществом.',
    },
};
