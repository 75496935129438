<fainin-layout>
    <ng-template #navLinks>
        <div faininShadow class="priority-nav-container paragraph-large">
            <div
                class="nav-link-item"
                [routerLink]="'/search'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                <img
                    class="drawer-icon"
                    src="/assets/icon/figma/search.svg"
                    alt="{{ 'asset.searchIcon' | translate }}" />
                <p>
                    {{ 'navigation.search' | translate }}
                </p>
            </div>
            <div
                class="nav-link-item plus"
                [routerLink]="'/account/product-edit'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                <img
                    class="drawer-icon"
                    src="/assets/icon/account-menu-mobile/plus.svg"
                    alt="{{ 'asset.uploadIcon' | translate }}" />
                <p>
                    {{ 'navigation.uploadProduct' | translate }}
                </p>
            </div>
        </div>
        <div class="nav-link-container paragraph-large">
            <div
                class="nav-link-item"
                [routerLink]="'/community'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'navigation.communities' | translate }}
            </div>
            <div
                class="nav-link-item"
                [routerLink]="'/about'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'navigation.about' | translate }}
            </div>
            <div
                class="nav-link-item"
                [routerLink]="'/faq'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'navigation.faq' | translate }}
            </div>
            <div
                class="nav-link-item"
                [routerLink]="'/blog'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'navigation.blog' | translate }}
            </div>
            <div
                class="nav-link-item"
                [routerLink]="'/press'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'navigation.press' | translate }}
            </div>
            <div
                *ngIf="isDebugEnabled"
                class="nav-link-item"
                [routerLink]="'/development'"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'Development' }}
            </div>
        </div>
    </ng-template>

    <fainin-layout-header #header *ngIf="(hideHeader$ | async) === false">
        <fainin-layout-logo></fainin-layout-logo>
        <!-- Always display search -->
        <fainin-layout-search-bar> </fainin-layout-search-bar>
        <fainin-account-menu class="ml-auto flex-nowrap flex-row"></fainin-account-menu>
    </fainin-layout-header>

    <fainin-layout-navigation-header #navigationHeader *ngIf="!isMobile && loggedIn && showSecondaryNavigation()">
    </fainin-layout-navigation-header>

    <div
        *ngIf="menuDrawerVisible$ | async"
        class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"
        (click)="closeMenuDrawer()">
        <fainin-menu-drawer
            class="light"
            id="menuDrawer"
            [visible]="menuDrawerVisible$ | async"
            (closeDrawer)="closeMenuDrawer()">
            <div class="mb-3">
                <ng-container *ngTemplateOutlet="navLinks"></ng-container>
            </div>
            <fainin-language-select></fainin-language-select>
            <a *ngIf="isSignedIn$ | async" class="logout-text" (click)="doSignOut()">{{
                'account.logout' | translate
            }}</a>
        </fainin-menu-drawer>
    </div>

    <fainin-cookie-consent
        *ngIf="(consentDialogueOpen$ | async) === true && !isCrawler"
        stateToShow="ADVANCED"></fainin-cookie-consent>

    <router-outlet></router-outlet>

    <fainin-feedback-modal
        (closeDesktopModal)="feedbackWizardOpen = !feedbackWizardOpen"
        [order]="awaitingFeedbackOrder"
        *ngIf="!isMobile && feedbackWizardOpen && awaitingFeedbackOrder">
    </fainin-feedback-modal>
    <fainin-action-sheet
        [hasBackButton]="true"
        (feedbackPrevStep)="previousFeedbackStep()"
        *ngIf="isMobile && awaitingFeedbackOrder"
        [(open)]="feedbackWizardOpen"
        title="{{ 'account.orderRequestStateText.review' | translate }}"
        topBoundary="{{ feedbackWizardActionSheetTopBoundary }}">
        <fainin-feedback-wizard [order]="awaitingFeedbackOrder"></fainin-feedback-wizard>
    </fainin-action-sheet>

    <fainin-secondary-footer-content *ngIf="!isAuthPage()"></fainin-secondary-footer-content>
    <fainin-layout-footer *ngIf="!isAuthPage()">
        <fainin-footer-content [version]="version" [currentYear]="currentYear"></fainin-footer-content>
    </fainin-layout-footer>
</fainin-layout>

<fainin-cookie-consent *ngIf="!isCrawler" stateToShow="SIMPLE"></fainin-cookie-consent>
