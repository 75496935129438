import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorPolicy, NetworkStatus, OperationVariables, WatchQueryFetchPolicy } from '@apollo/client/core';
import { QueryResult } from '@core/providers/data/query-result';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private readonly context = {
        headers: {},
    };

    constructor(private apollo: Apollo, private http: HttpClient) {}

    watchQuery<T = any, V extends OperationVariables = Record<string, any>>(
        query: DocumentNode,
        variables?: V,
        fetchPolicy?: WatchQueryFetchPolicy,
        errorPolicy?: ErrorPolicy,
    ): QueryResult<T, V> {
        const queryRef = this.apollo.watchQuery<T, V>({
            query,
            variables,
            context: this.context,
            fetchPolicy: fetchPolicy || 'cache-and-network',
            errorPolicy: errorPolicy || 'all',
        });

        return new QueryResult<T, V>(queryRef, this.apollo);
    }

    /**
     * Executed an apollo watch query with channel token in context header
     * @param query
     * @param variables
     * @param token
     * @param fetchPolicy
     * @param errorPolicy
     */
    watchQueryInChannel<T = any, V extends OperationVariables = Record<string, any>>(
        query: DocumentNode,
        variables?: V,
        token?: string,
        fetchPolicy?: WatchQueryFetchPolicy,
        errorPolicy?: ErrorPolicy,
    ): QueryResult<T, V> {
        const queryRef = this.apollo.watchQuery<T, V>({
            query,
            variables,
            context: token ? { headers: { 'vendure-token': token } } : undefined,
            fetchPolicy: fetchPolicy || 'cache-and-network',
            errorPolicy: errorPolicy || 'all',
        });

        return new QueryResult<T, V>(queryRef, this.apollo);
    }

    query<T = any, V extends OperationVariables = any>(
        query: DocumentNode,
        variables?: V,
        fetchPolicy?: WatchQueryFetchPolicy,
        errorPolicy?: ErrorPolicy,
    ): Observable<T> {
        return this.apollo
            .watchQuery<T, V>({
                query,
                variables,
                context: this.context,
                fetchPolicy: fetchPolicy || 'cache-and-network',
                errorPolicy: errorPolicy || 'all',
            })
            .valueChanges.pipe(
                filter(result => result.networkStatus === NetworkStatus.ready),
                map(response => response.data),
            );
    }

    queryWithChannelToken<T = any, V extends OperationVariables = any>(
        query: DocumentNode,
        variables?: V,
        token?: string,
        fetchPolicy?: WatchQueryFetchPolicy,
        errorPolicy?: ErrorPolicy,
    ): Observable<T> {
        return this.apollo
            .watchQuery<T, V>({
                query,
                variables,
                context: token ? { headers: { 'vendure-token': token } } : undefined,
                fetchPolicy: fetchPolicy || 'cache-first',
                errorPolicy: errorPolicy || 'all',
            })
            .valueChanges.pipe(
                filter(result => result.networkStatus === NetworkStatus.ready),
                map(response => response.data),
            );
    }

    mutate<T = any, V = any>(mutation: DocumentNode, variables?: V): Observable<T> {
        return this.apollo
            .mutate<T, V>({
                mutation,
                variables,
                context: this.context,
            })
            .pipe(map(response => response.data as T));
    }

    async clearCache() {
        // Reset the cache instead of the whole store
        await this.apollo.client.cache.reset();
    }
}
