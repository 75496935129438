import { OverlayModule } from '@angular/cdk/overlay';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { APP_ID, Inject, makeStateKey, NgModule, TransferState } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, UrlSerializer } from '@angular/router';
import { ApolloCoreModule } from '@core/apollo.module';
import { AccountMenuComponent } from '@core/components/account-menu/account-menu.component';
import { CollectionRowComponent } from '@core/components/collection-row/collection-row.component';
import { MenuDrawerModule } from '@core/components/menu-drawer/menu-drawer.module';
import { UniversalCookieConsentModule } from '@core/cookie-consent/cookie-consent.module';
import { CoreModule } from '@core/core.module';
import { LayoutCoreModule } from '@core/layout-core.module';
import { TranslateEmbeddedLoader } from '@core/providers/i18n.service';
import { PageMetadataDirective } from '@core/seo/page-metadata-directive.directive';
import { environment } from '@env/environment';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AssetPreviewSharedModule } from '@shared/asset-preview-shared.module';
import { ButtonSharedModule } from '@shared/button-shared.module';
import { ActionSheetModule } from '@shared/components/action-sheet/action-sheet.module';
import { LanguageSelectComponent } from '@shared/components/language-select/language-select.component';
import { FooterSharedModule } from '@shared/footer-shared.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { de_DE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MarkdownModule } from 'ngx-markdown';
import { filter } from 'rxjs/operators';
import { FeedbackWizardModule } from './account/modules/feedback-wizard/feedback-wizard.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { cookieConfig } from './cookieconsent.config';

registerLocaleData(de);
registerLocaleData(en);

const STATE_KEY = makeStateKey<any>('apollo.state');

// AoT requires an exported function for factories
export function EmbeddedLoaderFactory() {
    return new TranslateEmbeddedLoader();
}

@NgModule({
    declarations: [AppComponent, CollectionRowComponent, LanguageSelectComponent, PageMetadataDirective],
    imports: [
        ActionSheetModule,
        AccountMenuComponent,
        ApolloCoreModule,
        AppRoutingModule,
        AssetPreviewSharedModule,
        BrowserAnimationsModule,
        BrowserModule,
        ButtonSharedModule,
        FeedbackWizardModule,
        FooterSharedModule,
        HttpClientJsonpModule, // required for mailchimp
        HttpClientModule,
        LayoutCoreModule,
        MarkdownModule.forRoot(),
        MenuDrawerModule,
        NzDropDownModule,
        OverlayModule,
        TransferHttpCacheModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: EmbeddedLoaderFactory,
            },
        }),
        UniversalCookieConsentModule.forRoot(cookieConfig),
        CoreModule,
        // Using the service worker appears to break SSR after the initial page load.
        /*ServiceWorkerModule.register(`${environment.baseHref}ngsw-worker.js`, {
        enabled: environment.production,
        registrationStrategy: 'registerWithDelay:5000',
    }),*/
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: APP_ID, useValue: 'faininApp' },
        { provide: NZ_I18N, useValue: de_DE },
        NzNotificationService,
    ],
})
export class AppModule {
    constructor(
        private readonly transferState: TransferState,
        private router: Router,
        private urlSerializer: UrlSerializer,
        @Inject(DOCUMENT) private document?: Document,
    ) {
        const isBrowser = this.transferState.hasKey<any>(STATE_KEY);

        if (isBrowser) {
            //this.onBrowser();
            this.handleScrollOnNavigations();
        } else {
            //this.onServer();
        }
    }

    /*    onServer() {
        this.transferState.onSerialize(STATE_KEY, () => {
            const state = this.coreModule.extractState();
            return state;
        });
    }*/

    /*    onBrowser() {
        const state = this.transferState.get<any>(STATE_KEY, null);
        this.coreModule.restoreState(state);
    }*/

    /**
     * A work-around for undesirable scoll behaviour caused by the router's `scrollPositionRestoration` setting.
     * When set to 'enabled', it correctly handles scrolling to the top on navigation, and preserving scroll position
     * on "back" navigation. However, it _also_ causes the page to scroll to the top when changing search facet value filters,
     * which is very undesirable. Since there seems to be currently no way to disable the scrolling on a per-navigation basis,
     * we are manually implementing scroll-to-top-on-nav and adding an exception for when the "facets" param of the "category"
     * routes change.
     */
    private handleScrollOnNavigations() {
        this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(event => {
            if (this.document?.defaultView) {
                const parsed = this.urlSerializer.parse(event.urlAfterRedirects);
                const primaryRoot = parsed.root.children.primary;
                const isFacetFilterNavigation =
                    primaryRoot?.segments[0]?.path === 'category' &&
                    primaryRoot?.segments[1]?.parameterMap.has('facets');

                if (!isFacetFilterNavigation) {
                    this.document.defaultView.scrollTo({
                        top: 0,
                    });
                }
            }
        });
    }
}
