import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DebounceClickDirective } from '@shared/directive/debounce-click.directive';
import { ShadowDirective } from '@shared/directive/shadow.directive';
import { ButtonComponent } from './components/button/button.component';
import { PillComponent } from './components/pill/pill.component';

@NgModule({
    declarations: [ButtonComponent, ShadowDirective, PillComponent],
    imports: [RouterModule, DebounceClickDirective, NgIf, AsyncPipe, NgTemplateOutlet],
    exports: [ButtonComponent, ShadowDirective, PillComponent],
})
export class ButtonSharedModule {}
