import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@core/utils/logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export interface LayoutState {
    showFooter: boolean;
    showSecondaryFooter: boolean;
    showSubHeader: boolean;
    showHeader: boolean;
    blockScrolling: boolean;
    showTabBar: boolean;
}

export const initialState: LayoutState = {
    showFooter: true,
    showSecondaryFooter: true,
    showSubHeader: true,
    showHeader: true,
    blockScrolling: false,
    showTabBar: true,
};

const logger = new Logger('LayoutService');

export const LayoutGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const layoutService = inject(LayoutService);

    // Update only the properties that are defined in the route data
    const newState = {
        ...(route.data['blockScrolling'] !== undefined && { blockScrolling: route.data['blockScrolling'] }),
        ...(route.data['showSubHeader'] !== undefined && { showSubHeader: route.data['showSubHeader'] }),
        ...(route.data['showFooter'] !== undefined && { showFooter: route.data['showFooter'] }),
        ...(route.data['showSecondaryFooter'] !== undefined && {
            showSecondaryFooter: route.data['showSecondaryFooter'],
        }),
        ...(route.data['showHeader'] !== undefined && { showHeader: route.data['showHeader'] }),
        ...(route.data['showTabBar'] !== undefined && { showTabBar: route.data['showTabBar'] }),
    };

    layoutService.updateState(newState);
    return true;
};

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    protected state: LayoutState;
    private readonly stateSubject = new BehaviorSubject<LayoutState>({ ...initialState });

    constructor() {
        this.state = { ...initialState };
        this.stateSubject.next(this.state);
    }

    setState<T extends keyof LayoutState>(key: T, value: LayoutState[T]) {
        logger.debug('SET', key, 'TO', value);
        this.state[key] = value;
        this.stateSubject.next(this.state);
    }

    updateState(value: Partial<LayoutState>) {
        logger.debug('PATCH', value);
        this.state = { ...this.state, ...value };
        this.stateSubject.next(this.state);
    }

    select<R>(selector: (state: LayoutState) => R): Observable<R> {
        return this.stateSubject.pipe(map(selector), distinctUntilChanged());
    }

    currentState<T extends keyof LayoutState>(key: T): LayoutState[T] {
        return this.stateSubject.value[key];
    }

    resetLayoutState() {
        this.state = { ...initialState }; // copy!
        this.stateSubject.next(initialState);
    }
}
