import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
    selector: 'fainin-menu-drawer',
    templateUrl: './menu-drawer.component.html',
    styleUrls: ['./menu-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDrawerComponent {
    @HostBinding('class.visible')
    @Input()
    visible = false;

    @HostBinding('class.foreground')
    @Input()
    foreground = false;

    @HostBinding('class.large')
    @Input()
    large = false;

    @Input() title: string | undefined;
    @Input() closeable = true;

    @Output() closeDrawer = new EventEmitter<void>();

    constructor() {}

    doClose() {
        this.closeDrawer.emit();
    }
}
